import { FelaCSS, alpha, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  heading: FelaCSS;
  imgContainer: FelaCSS;
  imgCaption: FelaCSS;
  subTitle: FelaCSS;
  statusMsg: FelaCSS;
}

const styles = (): IStyles => {
  const subTitleText: FelaCSS = {
    marginBottom: '11px',
    fontDefaultSemiBold: 16,
    lineHeight: '22px',
  };

  return {
    wrapper: {
      alignItems: 'center',

      maxWidthTabletSm: {
        paddingLeft: '20px',
        paddingRight: '20px',
      },
    },

    heading: {
      marginTop: '22px',
      marginBottom: '30px',
      fontDefaultSemiBold: 40,
      color: colors.space,
      textAlign: 'center',

      maxWidthTabletSm: {
        fontDefaultSemiBold: 30,
        lineHeight: '33px',
      },
    },

    imgContainer: {
      position: 'relative',
      marginBottom: '12px',
      maxWidth: '660px',
      width: '100%',
    },

    imgCaption: {
      position: 'absolute',
      left: '0',
      bottom: '0',
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingTop: '10px',
      paddingBottom: '10px',
      width: '100%',
      fontDefaultSemiBold: 14,
      lineHeight: '20px',
      color: colors.white,
      backgroundColor: alpha(colors.space, 70),
    },

    subTitle: {
      ...subTitleText,
      display: 'block',
      width: '100%',
      textAlign: 'center',

      color: colors.space,
    },

    statusMsg: {
      ...subTitleText,
      color: colors.white,
    },
  };
};

export default styles;
